import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"

const Diners = ({ data }) => {
  const {
    allAirtable: { nodes: diners },
  } = data
  return (
    <Layout>
      <div className="-mx-8">
        {diners.map(diner => (
          <div className="px-8">{diner.data.Name}</div>
        ))}
      </div>
    </Layout>
  )
}

export default Diners

export const query = graphql`
  query DinersQuery {
    allAirtable(filter: { table: { eq: "Diners Club" } }) {
      nodes {
        data {
          Name
          Address
          Neighborhood
        }
      }
    }
  }
`
